var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-page"},[_c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c('v-card',{staticClass:"auth-card"},[_c('v-card-title',{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_c('span',{staticClass:"primary--text"},[_vm._v("Strong Expert")])])])],1),_c('v-card-text',[_c('validation-observer',{ref:"formResetPassword",attrs:{"autocomplete":"off","tag":"form"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.update()}}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required","vid":"user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":"Nome","disabled":"","dense":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})]}}])}),_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":"E-mail","disabled":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('validation-provider',{attrs:{"name":"Nova Senha","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"error-messages":errors,"type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Nova Senha","outlined":"","dense":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_c('validation-provider',{attrs:{"name":"Confirmar Nova Senha","rules":"required|confirmed:password","vid":"passwordConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.isPasswordConfirmationVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"error-messages":errors,"type":_vm.isPasswordConfirmationVisible ? 'text' : 'password',"label":"Confirmar Nova Senha","outlined":"","dense":""},on:{"click:append":function($event){_vm.isPasswordConfirmationVisible = !_vm.isPasswordConfirmationVisible}},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})]}}])}),_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Alterar Senha ")])],1)],1),_c('v-card-text',{staticClass:"d-flex align-center justify-center flex-wrap mt-2"},[_c('span',{staticClass:"me-2"},[_vm._v(" Já tem uma conta? ")]),_c('router-link',{attrs:{"to":{ name:'signin' }}},[_vm._v(" Faça login ")])],1)],1)],1)]),_vm._l((6),function(index){return _c('div',{key:index,staticClass:"cube"})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="auth-page">
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <h2 class="text-2xl font-weight-semibold">
                <span class="primary--text">Strong Expert</span>
              </h2>
            </router-link>
          </v-card-title>

          <v-card-text>
            <validation-observer
              ref="formResetPassword"
              autocomplete="off"
              tag="form"
              @keyup.enter="update()"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Nome"
                rules="required"
                vid="user"
              >
                <v-text-field
                  v-model="user"
                  :error-messages="errors"
                  outlined
                  label="Nome"
                  disabled
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="E-mail"
                rules="required|email"
                vid="email"
              >
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  outlined
                  label="E-mail"
                  disabled
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Nova Senha"
                rules="required"
                vid="password"
              >
                <v-text-field
                  v-model="password"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :error-messages="errors"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Nova Senha"
                  outlined
                  dense
                  @click:append="isPasswordVisible = !isPasswordVisible"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Confirmar Nova Senha"
                rules="required|confirmed:password"
                vid="passwordConfirmation"
              >
                <v-text-field
                  v-model="passwordConfirmation"
                  :append-icon="isPasswordConfirmationVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :error-messages="errors"
                  :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                  label="Confirmar Nova Senha"
                  outlined
                  dense
                  @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                />
              </validation-provider>

              <v-btn
                block
                color="primary"
                @click="update()"
              >
                Alterar Senha
              </v-btn>
            </validation-observer>
          </v-card-text>

          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2">
              Já tem uma conta?
            </span>
            <router-link :to="{ name:'signin' }">
              Faça login
            </router-link>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div
      v-for="index in 6"
      :key="index"
      class="cube"
    />
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const email = ref('')
    const user = ref('')
    const password = ref('')
    const passwordConfirmation = ref('')

    return {
      isPasswordVisible,
      isPasswordConfirmationVisible,
      email,
      user,
      password,
      passwordConfirmation,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

  async created() {
    const { data, status } = await this.tokenVerify(this.$route.query.token)

    if (status !== 200) {
      this.$router.push('/signin')
    }

    const { email, user } = data.payload

    this.email = email
    this.user = user
  },

  methods: {
    ...mapActions('auth', [
      'updatePassword',
      'tokenVerify',
    ]),

    async update() {
      if (await this.$refs.formResetPassword.validate()) {
        const status = await this.updatePassword({
          token: this.$route.query.token,
          password: this.$sha256(this.password),
          passwordConfirm: this.$sha256(this.passwordConfirmation),
        })

        if (status === 200) {
          this.$router.push('/signin')
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
